label {
  font-weight: bold;
}

.text-additional {
  font-size: 0.75rem;
  opacity: 0.75;
}

.App {}

.bg {
  position: fixed;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: -1; }

  .bg .line {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-repeat: repeat;
    background-position: center;
    background-size: 1000px; }

  .bg .line {
    height: 200%;
    /* background-image: url("../img/main/bg_line.png"); */
    background-image: url("assets/img/bg_line.png"); }

@media (min-width: 992px) {
  .container {
    max-width: 50rem !important;
  }
}

button {
  cursor: pointer;
}

.font-condensed {
  font-family: 'Open Sans Condensed', sans-serif;
}

.font-monospace {
  font-family: 'Roboto Mono', monospace;
}

.clickable {
  cursor: pointer !important;
}

.text-underline {
  text-decoration: underline !important;
}

.a, .clickable-text {
  color: $link-color;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  cursor: pointer;
}

.a:hover, .clickable-text:hover {
  color: #034c9b;
  text-decoration: underline;
}

.solid-rectangle-button {
  border: none;
  padding: 0.5rem 1rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.5);
  font-weight: bold;
}

.bg-golden {
  background-color: #d6a838;
  color: white;
}

.bg-trust-wallet-blue {
  background-color: #2C6AB3;
  color: white;
}

.trust-wallet-icon {
  height: 2.25em;
  width: 2.25em;
  border-radius: 0.625em;
}

.balance-container {
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
}

.transfer-info-container {
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (min-width: 768px){
  .balance-icon-container {
    margin-right: 1rem;
  }
  .balance-text-container {
  }
}

@media screen and (max-width: 767px){
  .balance-icon-container {
    float: left;
  }
  .balance-icon-container img {
    vertical-align: top;
  }
  .balance-text-container {
    float: right;
  }
}

.narrow-container {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}

.copied-msg {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -125%);
  font-size: 0.875rem;
  border-radius: 0.25rem;

  padding: 0.25rem 0.5rem;
  background-color: #333;
  color: white;
  z-index: 1000;
}

.word-break-all {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
}

.word-wrap-break-word {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.custom-navbar {
  position: sticky;
  top: 0;
  line-height: 3rem;
  background-color: rgba(0, 0, 0, 0.15);
  padding-left: 1rem;
  padding-right: 1rem;
  box-sizing: border-box;
}

.app-title {
  font-size: 1.25rem;
  font-weight: bold;
  padding: 0.5rem;
}

.custom-nav-link {
  padding: 0.5rem;
}

.tab-list {
  border-bottom: 1px #d6a838 solid;
  font-size: 1.25rem;
  text-align: center;
}

.tab-button {
  padding: 0.5rem 1rem;
  display: inline-block;
  text-align: center;
  font-weight: bold;
}

.tab-button-inactive {
  background-color: transparent;
  color: #d6a838;
}

.tab-button-active {
  background-color: #d6a838;
  color: white;
}

.address-name-logo {
  max-height: 10rem;
  max-width: 100%;
}

.orders-table {
  border-spacing: 0;
  border-collapse: collapse;
  text-align: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  font-size: 0.875rem;
}

.order-pending {
  font-style: italic;
  opacity: 0.5;
}

.order-error {
  opacity: 0.5;
}

.merchant-currency-selector {
  color: white;
  background-color: transparent;
  width: 6rem;
  margin: auto;
  text-align: center;
  text-align-last: center;
  /* border: none; */

  /* -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; */
}

.merchant-currency-option {
  text-align: center;
}

.text-underlined {
  text-decoration: underline;
}

@mixin modal-button {
  position: absolute;
  cursor: pointer;
  z-index: 1;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  line-height: 1;
  width: 3.5rem;
  height: 3.5rem;
  text-align: center;
  padding: 0;
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.25rem;
  font-weight: 100;
}

.custom-modal-close-button {
  @include modal-button;
  top: 0;
  right: 0;
}

.custom-modal-back-button {
  @include modal-button;
  top: 0;
  left: 0;
}

.wallet-app-icon {
  width: 4rem;
  border-radius: 1rem;
}

.position-absolute-right-over-top {
  position: absolute;
  right: 0;
  top: -1rem;
}

.wallet-app-block {
  width: 9rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}
