.lang-select {
  background-color: transparent;
  border: none;
  outline: none;
  color: #fff;
  margin: 0;
  padding: 0 0.5rem;
  cursor: pointer;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border: 0;
}
