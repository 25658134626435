.address-input {
  width: 100%;
  color: white;
  background-color: transparent;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #d6a838;
  border-radius: 0;
  text-align: center;
  font-weight: 100;
  font-family: 'Open Sans Condensed', sans-serif;
}

/* font-size = (w - 30) / 250 rem, 1rem = 16px */

.address-text {
  font-size: 2rem;
}

@media screen and (max-width: 767px){
  /* input width = 540 - 30 px*/
  .address-text {
    font-size: 1.75rem;
  }
}

@media screen and (max-width: 575px){
  .address-text {
    font-size: 1.75rem;
  }
}

@media screen and (max-width: 540px){
  .address-text {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 467px) {
  .address-text {
    font-size: 1.375rem;
  }
}

@media screen and (max-width: 430px) {
  .address-text {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 394px) {
  .address-text {
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 357px) {
  .address-text {
    font-size: 1rem;
  }
}

@media screen and (max-width: 321px) {
  .address-text {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 285px) {
  .address-text {
    font-size: 0.75rem;
  }
}
