// Darkly 4.2.1
// Bootswatch

//
// Color system
//

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #ebebeb !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #999 !default;
$gray-700: #444 !default;
$gray-800: #303030 !default;
$gray-900: #222 !default;
$black:    #000 !default;

$blue:    #375a7f !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #E74C3C !default;
$orange:  #fd7e14 !default;
$yellow:  #F39C12 !default;
$green:   #00bc8c !default;
$teal:    #20c997 !default;
$cyan:    #3498DB !default;

// Custom Colors

$green-bg: #0a2b17 !default;
$gold: #d6a838 !default;

$primary:       $gold !default;
$secondary:     $white !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-800 !default;
$dark:          $gray-500 !default;

$yiq-contrasted-threshold:  175 !default;

// Body

$body-bg:                   $green-bg !default;
$body-color:                $gold !default;

// Modals

$modal-content-bg:                  $green-bg !default;
$modal-content-border-color:        transparent !default;

$modal-header-border-color:         transparent !default;

$modal-backdrop-bg:                 black !default;
$modal-backdrop-opacity:            0.75 !default;

// Links

$link-color:                #85cee6 !default;

// Fonts

$font-family-sans-serif:      "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

// $font-size-base:              0.9375rem !default;

// $h1-font-size:                3rem !default;
// $h2-font-size:                2.5rem !default;
// $h3-font-size:                2rem !default;

// // Tables

// $table-accent-bg:             $gray-800 !default;

// $table-border-color:          $gray-700 !default;

// $table-dark-bg:               $gray-500 !default;
// $table-dark-border-color:     darken($gray-500, 7.5%) !default;
// $table-dark-color:            $body-bg !default;

// // Forms

// $input-border-color:                    transparent !default;

// $input-group-addon-color:               $gray-500 !default;
// $input-group-addon-bg:                  $gray-700 !default;

// $custom-file-color:                 $gray-500 !default;
// $custom-file-border-color:          $gray-700 !default;

// // Dropdowns

// $dropdown-bg:                       $gray-900 !default;
// $dropdown-border-color:             $gray-700 !default;
// $dropdown-divider-bg:               $gray-700 !default;

// $dropdown-link-color:               $white !default;
// $dropdown-link-hover-color:         $white !default;
// $dropdown-link-hover-bg:            $primary !default;

// // Navs

// $nav-link-padding-x:                2rem !default;
// $nav-link-disabled-color:           $gray-500 !default;

// $nav-tabs-border-color:             $gray-700 !default;
// $nav-tabs-link-hover-border-color:  $nav-tabs-border-color $nav-tabs-border-color transparent !default;
// $nav-tabs-link-active-color:        $white !default;
// $nav-tabs-link-active-border-color: $nav-tabs-border-color $nav-tabs-border-color transparent !default;

// // Navbar

// $navbar-padding-y:                  1rem !default;

// $navbar-dark-color:                 $white !default;
// $navbar-dark-hover-color:           $success !default;

// $navbar-light-color:                rgba($white,.5) !default;
// $navbar-light-hover-color:          $white !default;
// $navbar-light-active-color:         $white !default;
// $navbar-light-disabled-color:       rgba($white,.3) !default;
// $navbar-light-toggler-border-color: rgba($white,.1) !default;

// // Pagination

// $pagination-color:                  $white !default;
// $pagination-bg:                     $success !default;
// $pagination-border-width:           0 !default;
// $pagination-border-color:           transparent !default;

// $pagination-hover-color:            $white !default;
// $pagination-hover-bg:               lighten($success, 10%) !default;
// $pagination-hover-border-color:     transparent !default;

// $pagination-active-bg:              $pagination-hover-bg !default;
// $pagination-active-border-color:    transparent !default;

// $pagination-disabled-color:         $white !default;
// $pagination-disabled-bg:            darken($success, 15%) !default;
// $pagination-disabled-border-color:  transparent !default;

// // Jumbotron

// $jumbotron-bg:                      $gray-800 !default;

// // Cards

// $card-cap-bg:                       $gray-700 !default;
// $card-bg:                           $gray-800 !default;

// // Popovers

// $popover-bg:                        $gray-800 !default;

// $popover-header-bg:                 $gray-700 !default;

// // Progress bars

// $progress-height:                   0.625rem !default;
// $progress-font-size:                0.625rem !default;
// $progress-bg:                       $gray-700 !default;

// // List group

// $list-group-bg:                     $gray-800 !default;
// $list-group-border-color:           $gray-700 !default;

// $list-group-hover-bg:               $gray-700 !default;

// // Breadcrumbs

// $breadcrumb-bg:                     $gray-700 !default;

// Close

$close-color:                       $white !default;
$close-text-shadow:                 none !default;

// // Code

// $pre-color:                         inherit !default;

html {
  font-size: 16px;
}

@media (min-width: 768px) {
  html {
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 20px;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import '~bootstrap/scss/bootstrap.scss';
@import './App.scss';
