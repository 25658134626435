.doubleBorder {
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;

  display: inline-block;
  color: #d6a838;
  position: relative;
  border: 1px solid #d6a838;
  background-color: transparent;
}

.normalPadding {
  padding: 0.5rem 1rem;
}

.narrowPadding {
  padding: 0.25rem 0.625rem;
}

.doubleBorderTitle {
  margin-right: 1rem;
}

.doubleBorderLineSegment1 {
  position: absolute;
  background-color: #d6a838;
  right: -2px;
  top: 4px;
  width: 2px;
  height: 1px;
}

.doubleBorderLineSegment2 {
  position: absolute;
  background-color: #d6a838;
  right: -3px;
  top: 4px;
  width: 1px;
  height: 100%;
}

.doubleBorderLineSegment3 {
  position: absolute;
  background-color: #d6a838;
  right: -3px;
  bottom: -4px;
  width: 100%;
  height: 1px;
}

.doubleBorderLineSegment4 {
  position: absolute;
  background-color: #d6a838;
  left: 3px;
  bottom: -4px;
  width: 1px;
  height: 4px;
}
