.numeric-input {
  width: 100%;
  color: white;
  background-color: transparent;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #d6a838;
  border-radius: 0;
  font-size: 2rem;
  font-weight: 200;
  text-align: center;
}

.numeric-input.not-valid-input {
  border-color: red;
  color: red;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}
