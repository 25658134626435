/* WebKit and Opera browsers */
@-webkit-keyframes spinner {
  from { -webkit-transform: rotateY(0deg);    }
  to   { -webkit-transform: rotateY(-360deg); }
}

/* all other browsers */
@keyframes spinner {
  from {
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    -moz-transform: rotateY(-360deg);
    -ms-transform: rotateY(-360deg);
    transform: rotateY(-360deg);
  }
}

.spinner {
  background-image: url("../assets/img/selfcoin.png");
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  background-size: 4rem 4rem;
  background-position: center;
  z-index: 1001;

  animation-name: spinner;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 1s;

  -webkit-animation-name: spinner;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 1s;

  -webkit-transform-style: flat;
  -moz-transform-style: flat;
  -ms-transform-style: flat;
  transform-style: flat;
}

.spinner-cover-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;

  -webkit-transform-style: flat;
  -moz-transform-style: flat;
  -ms-transform-style: flat;
  transform-style: flat;
}

.spinner-cover-background {
  position: "relative";
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}
